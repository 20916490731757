import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";

function App() {

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/privacy-policy"
					element={<PrivacyPolicyPage />}
				></Route>
				<Route
					path="/"
					element={<HomePage />}
				></Route>

			</Routes>
		</BrowserRouter>

	);
}

export default App;
