import React from 'react';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';


const Modal = ({ setOpenModal }) => {
    const { register, formState: { errors }, handleSubmit } = useForm();

    console.log(setOpenModal);

    const handleGetInTouch = data => {
        // console.log('you are a beast');
        const GetInTouch = {
            companyName: data.companyName,
            companyPhone: data.phoneNumber,
            companyEmail: data.companyEmail,
            checkbox: data.checkbox
        }
        console.log(GetInTouch);

        fetch('https://heliverse.herokuapp.com/form', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(GetInTouch)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.message) {
                    toast.success('You successfully submit the form');
                    setOpenModal(false)

                }
                else {
                    toast.error('Sorry, your submission is not successfull');

                }

            });
    }

    return (
        <>


            <div className="modal-window">
                
                    <div className="modalContainer">
                        <div className="titleCloseBtn">
                            <button
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="title">
                            <h4>Put worries to rest and let us experts
                                achieve the goals for you.</h4>
                        </div>

                        <div className="body">

                            <form onSubmit={handleSubmit(handleGetInTouch)}>
                                <div className='body-1'>
                                    <div className='inside-body-1'>
                                        <input
                                            type="text"
                                            placeholder='Company name'
                                            {...register("companyName", {
                                                required: 'Company name is required'
                                            })}
                                        />
                                        <ErrorMessage errors={errors} name="companyName" />
                                    </div>
                                    <div className='inside-body-1'>
                                        <input
                                            type="number"
                                            placeholder='Phone number'
                                            {...register("phoneNumber", {
                                                required: 'Phone number is required'
                                            })}
                                        />
                                        <ErrorMessage errors={errors} name="phoneNumber" />
                                    </div>
                                </div>
                                <div className='body-2'>
                                    <input
                                        type="email"
                                        placeholder='Company Mail-Id'
                                        {...register("companyEmail", {
                                            required: 'Company Mail-Id is required'
                                        })}
                                    />
                                    <ErrorMessage errors={errors} name="companyEmail" />
                                </div>
                                <div className='body-3'>
                                    <div>
                                        <input {...register("checkbox")} type="checkbox" value="Website Development/ Design" />
                                        <label className='label-design'>Website Development/ Design</label>
                                    </div>
                                    <div>
                                        <input {...register("checkbox")} type="checkbox" value="Website Maintanence" />
                                        <label className='label-design'>Website Maintanence</label>
                                    </div>
                                    <div>
                                        <input {...register("checkbox")} type="checkbox" value="UI/ UX" />
                                        <label className='label-design'>UI/ UX</label>

                                    </div>
                                    <div>
                                        <input {...register("checkbox")} type="checkbox" value="Mobile App Development" />
                                        <label className='label-design'>Mobile App Development</label>
                                    </div>

                                </div>


                                <div className="submit-btn">
                                    <input type="submit" />
                                </div>

                            </form>

                        </div>

                   
                </div>


            </div>



        </>
    );
};

export default Modal;