import React from "react";
import huupe from "../assets/huupe-main.webm";
import stratum from "../assets/stratum.webm";
import findaa from "../assets/findaa.mp4";
// import Particles from "react-tsparticles";
// import particles from "../assets/particlesjs-config.json";
// import { loadFull } from "tsparticles";

// console.log({ particles });
const FeaturedProjects = () => {
	// const particlesInit = async (main) => {
	// 	console.log(main);

	// 	// you can initialize the tsParticles instance (main) here, adding custom shapes or presets
	// 	// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
	// 	// starting from v2 you can add only the features you need reducing the bundle size
	// 	await loadFull(main);
	// };
	// const particlesLoaded = (container) => {
	// 	console.log(container);
	// };
	return (
		<div className="featured">
			<div className="container">
				<h2 className="white aos">
					Featured<span className="projects">Projects</span>
					<div className="items">
						<div className="item">
							<div className="details">
								{/* <span className="count">1.</span> */}
								<h3>Huupe</h3>
								<h4>World's first smart basketball hoop</h4>
							</div>
							<div className="display">
								{/* <Particles
									id="tsparticles"
									options={particles}
									init={particlesInit}
									loaded={particlesLoaded}
								/> */}
								<video src={huupe} autoPlay loop muted></video>
							</div>
						</div>

						<div className="item">
							<div className="details">
								{/* <span className="count">2.</span> */}
								<h3>Stratum</h3>
								<h4>Create Custom Designer Furniture For Your Home</h4>
							</div>
							<div className="display">
								<video src={stratum} autoPlay loop muted></video>
							</div>
						</div>

						<div className="item">
							<div className="details">
								{/* <span className="count">3.</span> */}
								<h3>Findaa</h3>
								<h4>Best Asset Management Platform</h4>
							</div>
							<div className="display">
								<video src={findaa} autoPlay loop muted></video>
							</div>
						</div>
					</div>
				</h2>
			</div>
		</div>
	);
};

export default FeaturedProjects;
