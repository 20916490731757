import React from "react";
import cfobridge from "../assets/cfobridgeWhite.png";
import copcheck from "../assets/copcheck-logo.png";
import customerDiscovery from "../assets/customer-discovery.png";
import findaa from "../assets/findaa-logo.png";
import klipfit from "../assets/klipfit-logo.png";
import mip from "../assets/mip.png";
import mlt from "../assets/MLT.webp";
import nn from "../assets/99.webp";
import cs from "../assets/canned-splam.png";
import onecare from "../assets/one-care.png";
import rehome from "../assets/rehome-logo.png";
import scotia from "../assets/scotia-plaza.png";
import sru from "../assets/sru.webp";
import Slider from "react-slick";
import '../sass/components/marquee.scss'

const Companies = () => {
	let Ma = () => {
		function SampleNextArrow(props) {
			const { className, style, onClick } = props;
			return (
				<div
					className={className}
					style={{ ...style, display: "none", background: "black",  }}
					onClick={onClick}
				/>
			);
		}

		function SamplePrevArrow(props) {
			const { className, style, onClick } = props;
			return (
				<div
					className={className}
					style={{ ...style, display: "none", background: "black" }}
					onClick={onClick}
				/>
			);
		}

		const settings = {
			dots: false,
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			autoplay: true,
			speed: 1800,
			autoplaySpeed: 1800,
			pauseOnHover: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			cssEase: "linear"
		};
		return (
			<Slider {...settings}>
				<div>
					<img src={sru} alt="sru" className="company-img" />
				</div>
				<div>
					<img src={cfobridge} alt="cfobridge" className="company-img" />
				</div>

				<div>
					<img src={scotia} alt="scotia" className="company-img" />
				</div>

				<div>
					<img src={klipfit} alt="klipfit" className="company-img" />
				</div>

				<div>
					<img src={findaa} alt="findaa" className="company-img" />
				</div>

				<div>
					<img src={customerDiscovery} alt="customerDiscovery" className="company-img" />
				</div>
			</Slider>
		);
	};

	const Mb = () => {
		function SampleNextArrow(props) {
			const { className, style, onClick } = props;
			return (
				<div
					className={className}
					style={{ ...style, display: "none", background: "black",  }}
					onClick={onClick}
				/>
			);
		}

		function SamplePrevArrow(props) {
			const { className, style, onClick } = props;
			return (
				<div
					className={className}
					style={{ ...style, display: "none", background: "black" }}
					onClick={onClick}
				/>
			);
		}
		const settings = {
			dots: false,
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			autoplay: true,
			speed: 1800,
			autoplaySpeed: 1800,
			rtl: true,
			pauseOnHover: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			cssEase: "linear"
		};
		return (
			<Slider {...settings}>
				<div>
					<img src={mip} alt="mip" className="company-img" />
				</div>
				<div>
					<img src={copcheck} alt="copcheck" className="company-img" />
				</div>
				<div>
					<img src={mlt} alt="mlt" className="company-img" />
				</div>

				<div>
					<img src={nn} alt="nn" className="company-img" />
				</div>

				<div>
					<img src={cs} alt="cs" className="company-img" />
				</div>

				<div>
					<img src={onecare} alt="onecare" className="company-img" />
				</div>

				<div>
					<img src={rehome} alt="rehome" className="company-img" />
				</div>
			</Slider>
		);
	};
	return (
		<div className="companies">
			<Ma />
			<Mb />
		</div>
	);
};

export default Companies;