import React from 'react';
import complimentCard from '../assets/Group 26.png';
import aboutComplimenterIcon from '../assets/Group 21.png'

const Compliment = () => {
    return (
        <div className='compliment'>
            <div className='container'>
                <div className='compliment-title'>
                    <p>Good Words</p>
                    <h4>Happy Clients, Happy Us</h4>
                </div>
                <div className='compliment-details'>
                    <div className='compliment-card'>
                        <img src={complimentCard} alt="complimentter pic" />
                    </div>
                    <div className=''>
                        <div className='given-compliment'>
                            <p>Absolutely amazing service, worked through multiple aspects of a challenging project.</p>
                        </div>
                        <div className='about-complimenter'>
                            <div className='complimenter-name'>
                                <p>Jamesou</p>
                            </div>
                            <div className='complimenter-position'>
                                <div>
                                    <img src={aboutComplimenterIcon} alt="" />
                                </div>
                                <div>
                                    <p>Product Designer, Amazon</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Compliment;