import { useEffect, useRef, useState } from "react";
import { gsap, Power3 } from "gsap";
// import SmoothScroll from './components/SmoothScroll';
import Marquee from "../components/Marquee";
import FeaturedProjects from "../components/FeaturedProjects";
// import stratum from "./assets/stratum.webm";
import "../sass/styles.scss";
import ScrollTrigger from "gsap/ScrollTrigger";
import Compliment from "../components/Compliment";
import GetInTouch from "../components/GetInTouch";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../components/Modal";
import Header from "../components/Header";

gsap.registerPlugin(ScrollTrigger);
function HomePage() {
    const hero = useRef();
    const cursor = useRef();
    let moveTween = useRef();
    const videos = useRef();
    const el = useRef();
    const aos = useRef();
    const v = gsap.utils.selector(videos);
    const q = gsap.utils.selector(el);
    const aosQs = gsap.utils.selector(aos);
    const [blendClass, setBlendClass] = useState("");
    const [activeVideo, setActiveVideo] = useState("websites");
    const [modalOpen, setModalOpen] = useState(false);
    // eslint-disable-next-line
    useEffect(() => {
        aosQs(".aos").forEach((section) => {
            gsap.fromTo(
                section,
                {
                    y: 50,
                },
                {
                    scrollTrigger: { trigger: section },
                    autoAlpha: 1,
                    y: 0,
                    duration: 0.75,
                    stagger: 0.25,
                }
            );
        });

        let qi = q(".hero-inner-link-item");
        gsap.to(hero.current, {
            opacity: 1,
            duration: 2,
            ease: Power3.easeInOut,
        });

        // console.log("rendering 2 times in strict mode");
        qi.forEach((link) => {
            link.addEventListener("mouseenter", (e) => {
                scaleCursor(cursor.current.children[0], 0.8);
                setVideo(link);
            });
            link.addEventListener("mouseleave", (e) => {
                scaleCursor(cursor.current.children[0], 0);
            });
            link.children[1].addEventListener("mouseenter", () => {
                // cursor.classList.add("media-blend");
                setBlendClass("media-blend");
                scaleCursor(cursor.current.children[0], 1.2);
            });
            // Bring scale back down .8
            link.children[1].addEventListener("mouseleave", () => {
                setBlendClass("");
                // cursor.classList.remove("media-blend");
                scaleCursor(cursor.current.children[0], 0.8);
            });
        });
        // console.log("add");
        addEventListeners();
        moveTween.current = gsap.to(cursor.current, {
            duration: 0.4,
            ease: Power3.easeOut,
            opacity: 1,
        });

        return () => removeEventListeners();
        // eslint-disable-next-line
    }, []);

    const addEventListeners = () => {
        document.addEventListener("mousemove", handleMouseEvent);
    };

    const removeEventListeners = () => {
        document.removeEventListener("mousemove", handleMouseEvent);
    };
    const scaleCursor = (el, amount) => {
        // console.log({ el, amount });
        gsap.to(el, {
            duration: 1,
            scale: amount,
            ease: Power3.easeOut,
        });
    };
    const setVideo = (el) => {
        // Grab the data-video-src and make sure it matches the video that should be displayed
        let src = el.getAttribute("data-video-src");
        src !== activeVideo && setActiveVideo(src);
        let video = v(`#${src}`)[0];
        // let siblings = getSiblings(video);
        let siblings = v(`video`).filter((e) => e.id !== src);
        if (video.id === src) {
            gsap.set(video, { zIndex: 4, opacity: 1 });
            siblings.forEach((i) => {
                gsap.set(i, { zIndex: 1, opacity: 0 });
            });
        }
    };
    const handleMouseEvent = (t) => {
        moveTween.current.vars.x = t.x;
        moveTween.current.vars.y = t.y;
        moveTween.current.invalidate().restart();
    };

    return (
        // <SmoothScroll>

        <div ref={aos}>
            <section>
                <Header />
            </section>
            <section className="hero" ref={hero}>
                <div className="container">
                    <div className="hero-inner">
                        <div className="hero-inner-banner">
                            <div className="hero-inner-col left"></div>
                            <div className="hero-inner-col right">
                                <div className="hero-inner-title">
                                    <h1>Experts that Design, Develop and Manage your</h1>
                                </div>
                                <div className="hero-inner-links" ref={el}>
                                    <div data-video-src="websites" className="hero-inner-link-item">
                                        <div className="hero-inner-link-item-padding"></div>
                                        <a href="/">
                                            {" "}
                                            <span>Websites</span>
                                        </a>
                                    </div>
                                    <div data-video-src="apps" className="hero-inner-link-item">
                                        <div className="hero-inner-link-item-padding"></div>
                                        <a href="/">
                                            {" "}
                                            <span>Apps</span>
                                        </a>
                                    </div>
                                    <div className="hero-inner-link-item" data-video-src="branding">
                                        <div className="hero-inner-link-item-padding"></div>
                                        <a href="/">
                                            {" "}
                                            <span>Products</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-inner-footer">
                        <div className="button-position">
                            <button
                                className="btn10"
                                // className="cta"
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                            >
                                Get in touch
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="hero-video">
				<video className="aos" src={stratum} preload="auto" autoPlay muted loop id="main"></video>
			</section> */}
            <section className="trustedby">
                <div className="container">
                    <h2 className="aos">Trusted by 100s of startups</h2>
                    <Marquee className="aos" />
                </div>
            </section>
            <section>
                <FeaturedProjects />
            </section>
            {/* <!-- Cursor --> */}
            <div className={`cursor ${blendClass}`} ref={cursor}>
                <div className="cursor-media" ref={videos}>
                    <video
                        src="https://cuberto-cursor.netlify.app/websites.34d6b2cb.mp4"
                        preload="auto"
                        autoPlay
                        muted
                        loop
                        id="websites"
                    ></video>
                    <video
                        src="https://cuberto-cursor.netlify.app/apps.a71a6485.mp4"
                        preload="auto"
                        autoPlay
                        muted
                        loop
                        id="apps"
                    ></video>
                    <video
                        src="https://cuberto-cursor.netlify.app/branding.7d00588f.mp4"
                        preload="auto"
                        autoPlay
                        muted
                        loop
                        id="branding"
                    ></video>
                </div>
            </div>
            <section>
                <Carousel></Carousel>
            </section>
            <section>
                <Compliment></Compliment>
            </section>
            <section>
                <GetInTouch setModalOpen={setModalOpen}></GetInTouch>
            </section>
            <section>
                {/* <Footer></Footer> */}
            </section>
            <ToastContainer></ToastContainer>
            {modalOpen && <Modal setOpenModal={setModalOpen}></Modal>}
            <Footer />
        </div>

        //  </SmoothScroll>
    );
}

export default HomePage;
