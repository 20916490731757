import React from "react";
import Modal from "./Modal";

const GetInTouch = ({ setModalOpen, modalOpen }) => {
	return (
		<>
			<div className="container">
				<div className="get-in-touch">
					<div>
						<h4>
							Put worries to rest and let the experts at
							<br />
							<span>Heliverse</span> handle your online Business.
						</h4>
					</div>
					<div className="button-position">
						<button
							className="btn10"
							onClick={() => {
								setModalOpen(true);
							}}
						>
							Get in touch
						</button>
					</div>
				</div>
			</div>
			<div>{modalOpen && <Modal setOpenModal={setModalOpen}></Modal>}</div>
		</>
	);
};

export default GetInTouch;
