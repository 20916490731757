import React from 'react'
import He from "../assets/Tech Legendary..svg";

const Header = () => {
  return (
    <div className='header'>
      <div>
        {/* <img src={He} alt="tech-legendary-logo" className="header-logo" /> */}
      </div>
    </div>
  )
}

export default Header
