import React from "react";
import He from "../assets/tech-legendary-white.svg";

const Footer = () => {
	return (
		<div className="footer">
			<div className="container">

				<div className="wrapper">
					<p>315, Ocus Quantum Internal Road, Sector 51, Gurugram, Haryana</p>
					<a href="mailto:harshit@heliverse.com">harshit@heliverse.com</a>
					<br />
					<a href="/privacy-policy">Privacy Policy</a>

				</div>

				<div className="copyright">
					<p>Copyright 2023 Heliverse</p>
				</div>

				{/* <div className="footer-second-section">
					<div>
						<h4>Heliverse</h4>
					</div>
					<div>
						<p>
							<a href="mailto:support@heliverse.com">support@heliverse.com</a>
						</p>
					</div>
				</div>
				<div className="footer-third-section">
					<div>
						<p>
							Heliverse is a platform designed for Startups and Agile Enterprises alike to build &
							grow their business with quality work & structured services.
						</p>
					</div>
					<div>
						<p>
							#1111, Ocus Quantum, Sector 51,
							<br />
							Gurugram, Haryana
						</p>
					</div>
					<div>
						<p>Terms & Conditions</p>
						<p>Privacy Policy</p>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default Footer;
