import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import image1 from '../assets/image 3.png';
import image2 from '../assets/image 4.png';
import image3 from '../assets/image 5.png'



const Carousel = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,

                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='carousel' style={{
            backgroundImage: 'url(https://i.ibb.co/PNhCtYX/Rectangle-90.png)', backgroundRepeat: "no-repeat", backgroundSize: "cover",
        }} >
            <div className='container'>
                <div className='carousel-container-header'>
                    <p>Working Style</p>
                    <h4>How we deliver exceptional work</h4>
                </div>
                <div className='cards'>

                    <Slider {...settings}>
                        <div>
                            <div className='card-1'>
                                <div className='card-image'>
                                    <img src={image1} alt="Emphasis" />
                                </div>
                                <div className='card-details'>
                                    <h4>Emphasis</h4>
                                    <p>We come together to share ideas, discuss details to take your notion to final product.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='card-2'>
                                <div className='card-image'>
                                    <img src={image2} alt="UX- Market Research" />
                                </div>
                                <div className='card-details'>
                                    <h4>UX- Market Research</h4>
                                    <p>We take a look at your industry, market and competition to make better decisions.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='card-3'>
                                <div className='card-image'>
                                    <img src={image3} alt="Information Architecture" />
                                </div>
                                <div className='card-details'>
                                    <h4>Information Architecture</h4>
                                    <p>We move the project ahead, right from weekly deliveries to the plan is decided based on this.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='card-4'>
                                <div className='card-image'>
                                    <img src={image1} alt="Ideate" />
                                </div>
                                <div className='card-details'>
                                    <h4>Ideate</h4>
                                    <p>We take your ideate and build the ideal flow based on the information architecture and insights.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='card-5'>
                                <div className='card-image'>
                                    <img src={image2} alt="Prototype" />
                                </div>
                                <div className='card-details'>
                                    <h4>Prototype</h4>
                                    <p>A universal system that can be used by developers and designers to create and prototype on the go.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='card-6'>
                                <div className='card-image'>
                                    <img src={image3} alt="Iterate" />
                                </div>
                                <div className='card-details'>
                                    <h4>Iterate</h4>
                                    <p>A universal system that can be used by developers and designers to create and prototype on the go.</p>
                                </div>
                            </div>
                        </div>

                    </Slider>

                </div>
            </div>
        </div>
    );
};

export default Carousel;